import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { classes } from './SessionDetails.st.css';
import { useSettings } from '@wix/tpa-settings/dist/src/contexts/SettingsContext/hooks';
import settingsParams from '../../BookingServicePage/settingsParams';
import { DailySession } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';

export const SessionDetails: React.FC<DailySession> = ({
  startTime,
  durationText,
  staffName,
}) => {
  const settings = useSettings();
  const staffVisibility = settings.get(settingsParams.scheduleStaffVisibility);
  const durationVisibility = settings.get(
    settingsParams.scheduleDurationVisibility,
  );
  const showDuration = () =>
    durationVisibility ? (
      <div className={classes.detailWrapper}>
        <Text className={classes.duration} data-hook="duration-text">
          {durationText}
        </Text>
      </div>
    ) : null;
  const showStaff = () =>
    staffVisibility && staffName ? (
      <div className={classes.detailWrapper}>
        <Text className={classes.staff} data-hook="staff-name">
          {staffName}
        </Text>
      </div>
    ) : null;
  return (
    <div className={classes.root}>
      <Text className={classes.startTime} data-hook="start-time">
        {startTime}
      </Text>
      <div className={classes.details}>
        {showDuration()}
        {showStaff()}
      </div>
    </div>
  );
};
