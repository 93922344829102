import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { ISection, SectionTypes } from '../../types';
import { DescriptionSectionViewModel } from '../../../../service-page-view-model/description-section-view-modal/descriptionSectionViewModel';
import Section from '../Section';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import settingsParams from '../../settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

type DescriptionProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    viewModel?: DescriptionSectionViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<DescriptionProps>(({ t, viewModel, section, className }) => {
    const settings = useSettings();
    const withBookButton = section.bookButton && viewModel?.isBookOnlineAllowed;

    return withBookButton || viewModel?.description ? (
      <Section
        sectionType={SectionTypes.DESCRIPTION}
        withBookButton={withBookButton}
      >
        {viewModel?.description && (
          <div data-hook="description-wrapper">
            <SectionTitle
              title={
                settings.get(settingsParams.descriptionTitleText) ||
                t('app.description.title')
              }
              className={className}
              dataHook="description-title"
            />
            <SectionText
              text={viewModel.description}
              className={className}
              dataHook="description"
            />
          </div>
        )}
      </Section>
    ) : null;
  }),
);
