import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { st, classes, cssStates } from './TitleAndTagline.st.css';
import { AlignmentOptions, ISection, SectionTypes } from '../../types';
import Section from '../Section';
import { TitleAndTaglineViewModel } from '../../../../service-page-view-model/title-and-tagline-view-model/titleAndTaglineViewModel';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Camera } from 'wix-ui-tpa/dist/src/assets/icons/index';
import { useRenderLocation } from '../useRenderLocation';
import { useGlobalAlignment } from '../useGlobalAlignment';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../../settingsParams';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';

type TitleAndTaglineProps = InjectedExperimentsProps &
  InjectedTranslateProps & {
    viewModel?: TitleAndTaglineViewModel;
    section: ISection;
    className?: string;
  };

export default translate()(
  withExperiments<TitleAndTaglineProps>(
    ({ t, experiments, viewModel, className }) => {
      const location = useRenderLocation();
      const { mobile = false } = useTPAComponentsConfig();
      const settings = useSettings();
      const serviceTitle = () =>
        settings.get(settingsParams.serviceTitleVisibility) ? (
          <Text
            data-hook="title-and-tagline-title"
            className={classes.title}
            typography={TYPOGRAPHY.largeTitle}
            tagName="h2"
          >
            {viewModel?.title}
          </Text>
        ) : null;

      const serviceTagline = () =>
        settings.get(settingsParams.serviceTaglineVisibility) &&
        viewModel?.tagline ? (
          <Text
            data-hook="title-and-tagline-tagline"
            className={classes.tagline}
            typography={TYPOGRAPHY.runningText}
          >
            {viewModel.tagline}
          </Text>
        ) : null;

      const onlineBadge = () =>
        settings.get(settingsParams.onlineBadgeVisibility) &&
        viewModel?.onlineProvider ? (
          <Badge
            icon={<Camera />}
            data-hook="title-and-tagline-badge"
            priority={BADGE_PRIORITY.light}
            className={classes.badge}
          >
            {settings.get(settingsParams.onlineBadgeText) ||
              t('app.settings.sections.titles.default.online-badge.text')}
          </Badge>
        ) : null;

      return (
        <Section
          sectionType={SectionTypes.TITLE_TAGLINE}
          childrenAlignment={settings.get(
            settingsParams.titleAndTaglineAlignment,
          )}
        >
          <div
            className={st(
              classes.root,
              {
                alignment:
                  settings.get(settingsParams.titleAndTaglineAlignment) ||
                  useGlobalAlignment(),
                location,
                mobile,
              },
              className,
            )}
            data-hook="title-and-tagline-wrapper"
          >
            {onlineBadge()}
            {serviceTitle()}
            {serviceTagline()}
          </div>
        </Section>
      );
    },
  ),
);
