import React, { useState } from 'react';
import { classes } from './DailySessions.st.css';
import { DayDetails } from '../DayDetails/DayDetails';
import { SessionDetails } from '../SessionDetails/SessionDetails';
import Divider from '../Divider/Divider';
import { SchedulingDayViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import settingsParams from '../../BookingServicePage/settingsParams';
import { useSettings } from '@wix/tpa-settings/dist/src/contexts/SettingsContext/hooks';
import { LoadAllButton } from '../LoadAllButton/LoadAllButton';
import { useLoadAllSessions } from '../useLoadAllSessions';

export interface DailySessionsProps {
  viewModel: SchedulingDayViewModel;
}
export const DailySessions: React.FC<DailySessionsProps> = ({ viewModel }) => {
  const { weekday, day, month, dailySessions } = viewModel;
  const { numberOfSession, LoadAllSessions } = useLoadAllSessions({
    defaultValue: settingsParams.scheduleSessionLimit,
    totalNumberOfSessions: dailySessions.length,
  });

  return (
    <div className={classes.root} data-hook={'daily-sessions'}>
      <div className={classes.day}>
        <DayDetails month={month} day={day} weekday={weekday} />
      </div>
      <div className={classes.sessions}>
        {dailySessions
          .slice(0, numberOfSession)
          .map(({ staffName, startTime, durationText }) => (
            <>
              <SessionDetails
                startTime={startTime}
                durationText={durationText}
                staffName={staffName}
              />
              <Divider />
            </>
          ))}
        <LoadAllSessions />
      </div>
    </div>
  );
};
