import React from 'react';
import { st, classes } from './Body.st.css';
import { MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { SectionNotification } from 'wix-ui-tpa/SectionNotification';
import { BodyViewModel } from '../../../../service-page-view-model/body-view-model/bodyViewModel';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';
import settingsParams from '../../settingsParams';

type BodyProps = {
  viewModel: BodyViewModel;
  width: string;
  className?: string;
};

const BodyComponent: React.FC<BodyProps> = ({
  viewModel,
  width,
  className,
  children,
}) => {
  const settings = useSettings();
  return (
    <RenderLocationProvider value={MainComponents.BODY}>
      <div
        style={{ flexBasis: width }}
        className={st(
          classes.root,
          {
            alignment: settings.get(settingsParams.bodyAlignment),
            columnAlignment: settings.get(settingsParams.columnPosition),
          },
          className,
        )}
      >
        <div className={classes.columnWrapper}>
          {viewModel.message && (
            <div
              role="status"
              data-hook="no-book-button-message"
              className={classes.notificationBanner}
            >
              <SectionNotification type="default">
                <SectionNotification.Text>
                  {viewModel.message}
                </SectionNotification.Text>
              </SectionNotification>
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    </RenderLocationProvider>
  );
};

export default BodyComponent;
