import React from 'react';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { classes } from './LoadAllButton.st.css';
import { InjectedTranslateProps, translate } from 'yoshi-flow-editor-runtime';

export type LoadAllButtonProps = InjectedTranslateProps & {
  onClick;
};
export const LoadAllButtonComponent = ({ onClick, t }: LoadAllButtonProps) => {
  return (
    <TextButton
      className={classes.root}
      onClick={onClick}
      data-hook="load-all-button"
    >
      {t('app.scheduling.load-all-sessions.default')}
    </TextButton>
  );
};

export const LoadAllButton = translate()(LoadAllButtonComponent);
