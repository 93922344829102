import React from 'react';
import { classes, st } from './SectionText.st.css';
import { Text } from 'wix-ui-tpa/Text';
import { useRenderLocation } from '../useRenderLocation';
import { useTPAComponentsConfig } from '../useTPAComponentsConfig';

interface SectionTitleProps {
  text: string | undefined;
  dataHook?: string;
  className?: string;
  tagName?: string;
}

const SectionText: React.FC<SectionTitleProps> = ({
  text,
  className,
  dataHook = 'section-text',
  tagName = 'p',
}) => {
  const location = useRenderLocation();
  const { mobile = false } = useTPAComponentsConfig();

  return text ? (
    <Text
      data-hook={dataHook}
      className={st(classes.text, { location, mobile }, className)}
      tagName={tagName}
    >
      {text}
    </Text>
  ) : null;
};

export default SectionText;
